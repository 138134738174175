body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center, #root {
  margin: auto;
  width: 56%;
}

@media screen and (max-width: 1200px) {
  .center, #root {
    width: 80%;
  }  
}

@media screen and (max-width: 900px) {
  .center, #root {
    width: 90%;
  } 
  
}

@media screen and (max-witdh: 600px) {
  .center, #root {
    width: 100%;
  } 
}

h1 {
  text-align: center;
}

h2 {
  font-size: calc(.75rem + .9vw);
}

input[type=checkbox] {
  margin-right: 10px;
}

input[type=checkbox]:checked {
  background-color: #98abce;
  border-color: #98abce;
}

input[type=radio] {
  accent-color: #6c757d;
}

.next {
  width: 100%;
  padding: 6px 0;
  background-color: #98abce;
  border-color: #98abce;
}

.next:hover {
  background-color: #7a8db6;
  border-color: #7a8db6;
}

ul .btn {
  margin: 4px 8px;
}

.btn-secondary {
  border-color: #6c757d;
  color: #6c757d;
  background-color: inherit;
}

.btn-primary:focus {
  background-color: #98abce;
  border-color: #98abce;
  box-shadow: none;
}

.d-flex > div {
  margin: 0 1em;
}

.seemore {
  margin: 0;
  padding: 0;
  float: right
}

.subsection > td {
  border-top: hidden;
}

select {
  width: 30% !important;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
}

/*! CSS Used from: https://danielfrisch.com/wp-content/themes/wordpress-theme-template/assets/css/styles.css ; media=all */
@media all{
  img{display:block;max-width:100%;border:none;}
  *,*:before,*:after{box-sizing:inherit;}
  div.pictures ul li img{position:absolute;display:block;transition:opacity 0.5s ease-in-out;opacity:0;cursor:pointer;}
  }